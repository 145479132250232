<script setup lang="ts">
type SelectableItem = {
    id: number;
    label?: string;
    value?: string;
    color?: string;
};

type Props = {
    ariaLabel?: string;
    buttonFlex?: boolean;
    closeOnSelect?: boolean;
    dataTestId?: string;
    defaultChosenEntryId?: number | null;
    deselectElement?: boolean;
    disabled?: boolean;
    disabledEntries?: Array<SelectableItem> | null;
    entries?: Array<SelectableItem>;
    fitContent?: boolean;
    formLabel?: string;
    isIconOnly?: boolean;
    isResetted?: boolean;
    isRightAligned?: boolean;
    isSidebarIcon?: boolean;
    isToggle?: boolean;
    label?: string;
    offsetLeft?: number;
    offsetRight?: number;
    offsetTop?: number;
    selected?: SelectableItem;
    showAsLink?: boolean;
    showAsMenu?: boolean;
    showWithColor?: boolean;
    useOuterIcon?: boolean;
    variant?: 'gray' | 'white';
    hasNoLabel?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    ariaLabel: '',
    buttonFlex: false,
    closeOnSelect: false,
    dataTestId: '',
    defaultChosenEntryId: null,
    deselectElement: false,
    disabled: false,
    disabledEntries: null,
    entries: undefined,
    fitContent: false,
    formLabel: '',
    hasMinWidth50: false,
    hasNoLabel: false,
    isResetted: false,
    isRightAligned: false,
    isSidebarIcon: false,
    isToggle: false,
    label: '',
    offsetLeft: 0,
    offsetRight: 0,
    offsetTop: 2,
    selected: undefined,
    showAsLink: false,
    showAsMenu: false,
    showWithColor: false,
    useOuterIcon: false,
    variant: 'white',
});

const emit = defineEmits(['selection']);

const isOpen = ref(false);
const isShownOnTop = ref(false);
const chosenEntry: Ref<SelectableItem | undefined> = ref(undefined);
const container = ref(null);
const list: Ref<null | HTMLElement> = ref(null);
const button: Ref<null | HTMLElement> = ref(null);
const focusedListEntry: Ref<number | null> = ref(null);

const getEntryById = (id: number): SelectableItem | undefined => {
    const e = props.entries?.filter((x: SelectableItem) => x.id === id);
    if (e && e.length) {
        return e[0];
    }
    return undefined;
};

const entryClick = (id: number): void => {
    if (chosenEntry.value?.id === id && !props.showAsMenu) {
        chosenEntry.value = props.defaultChosenEntryId
            ? getEntryById(props.defaultChosenEntryId)
            : undefined;
    } else {
        chosenEntry.value = getEntryById(id);
    }
    emit('selection', chosenEntry.value);
    if (props.closeOnSelect) isOpen.value = false;
};

const onCloseDropdown = (focusToButton?: boolean): void => {
    focusedListEntry.value = null;
    if (focusToButton && button.value) {
        setTimeout(() => {
            button.value?.focus();
        }, 0);
    }
};

const toggleDropdown = (evt: Event, focusToButton?: boolean): void => {
    if (props.entries) {
        isOpen.value = !isOpen.value;
        if (!isOpen.value) {
            onCloseDropdown(focusToButton);
        }
        evt.stopPropagation();
    }
};

const setFocusToElem = (
    listElements: Array<HTMLLIElement>,
    idx: number,
): void => {
    if (idx < listElements.length && idx >= 0) {
        listElements[idx]?.setAttribute('tabindex', '0');
        if (
            focusedListEntry.value != null &&
            focusedListEntry.value < listElements.length
        ) {
            listElements[focusedListEntry.value].removeAttribute('tabindex');
        }
        listElements[idx].focus();
        focusedListEntry.value = idx;
    }
};

const isEntryDisabled = (entryId?: number): boolean => {
    if (
        props.disabledEntries &&
        props.disabledEntries.length &&
        entryId != null
    ) {
        return !!props.disabledEntries.find(
            (x: SelectableItem) => x.id === entryId,
        );
    }
    return false;
};

const focusListElement = (idx: number): void => {
    if (list.value != null) {
        const listElements = list.value.querySelectorAll(
            'li',
        ) as unknown as Array<HTMLLIElement>;
        if (listElements.length && idx < listElements.length) {
            setFocusToElem(listElements, idx);
        }
    }
};

const getEnabledNextIndex = (
    start: number,
    forward?: boolean,
    forceIterateOnce?: boolean,
): number => {
    let foundIndex = start;
    if (props.entries && props.entries.length) {
        let iteratedOnce = false;
        for (
            let idx = start;
            start >= 0 && start < props.entries.length;
            forward ? (idx += 1) : (idx -= 1)
        ) {
            if (!isEntryDisabled(props.entries[idx]?.id)) {
                foundIndex = idx;
                if (iteratedOnce || !forceIterateOnce) {
                    break;
                }
            }
            iteratedOnce = true;
        }
    }
    return foundIndex;
};

const keyHandler = (event: KeyboardEvent): void => {
    if (event.key === 'ArrowDown') {
        if (!isOpen.value) {
            isOpen.value = true;
            setTimeout(() => focusListElement(getEnabledNextIndex(0, true)), 0);
        } else {
            focusListElement(getEnabledNextIndex(0, true));
        }
        event.preventDefault();
    }
};

const listKeyHandler = (event: KeyboardEvent, currIdx: number): void => {
    if (list.value) {
        if (
            (event.key === ' ' || event.key === 'Enter') &&
            props.entries &&
            props.entries.length > currIdx
        ) {
            entryClick(props.entries[currIdx].id);
            event.preventDefault();
            return;
        }
        const listElements = list.value.querySelectorAll(
            'li',
        ) as unknown as Array<HTMLLIElement>;
        let newIdx = currIdx;
        switch (event.key) {
            case 'Home':
                newIdx = getEnabledNextIndex(0, true);
                break;
            case 'End':
                newIdx = getEnabledNextIndex(listElements.length - 1, false);
                break;
            case 'ArrowDown':
                newIdx = getEnabledNextIndex(currIdx, true, true);
                break;
            case 'ArrowUp':
                newIdx = getEnabledNextIndex(currIdx, false, true);
                break;
            case 'Escape':
                toggleDropdown(event, true);
                break;
            default:
        }
        if (newIdx !== focusedListEntry.value) {
            setFocusToElem(listElements, newIdx);
        }
        if (event.key !== 'Tab') {
            event.preventDefault();
        }
    }
};

onClickOutside(container, () => {
    isOpen.value = false;
    onCloseDropdown();
});

watch(
    () => isOpen.value,
    () => {
        if (!isOpen.value && button.value) {
            button.value.focus();
            focusedListEntry.value = null;
        }
    },
);

watchEffect(() => {
    if (props.isResetted) {
        chosenEntry.value = undefined;
    }
});

watchEffect(() => {
    if (props.selected) {
        chosenEntry.value = props.selected;
    }
});
</script>

<template>
    <div ref="container" class="relative" :data-testid="dataTestId">
        <BaseFormLabel :text="formLabel" :disabled="disabled" />
        <button
            ref="button"
            :data-testid="`MtbaseFormSingleSelect Button ${dataTestId}`"
            class="relative w-full rounded"
            :class="{
                'text-gray-400': disabled,
                'cursor-not-allowed': disabled,
                'border border-gray-300 ': !isToggle && !isOpen,
                'border border-gray-900': !isToggle && isOpen,
                'bg-gray-50':
                    !isToggle &&
                    (variant === 'gray' || (variant === 'white' && disabled)),
                'bg-white': variant === 'white' && !disabled && !isToggle,
                'text-gray-600': isToggle && !showAsLink,
                'text-white': showAsLink,
                'p-4': !isToggle,
                'text-left': !isToggle,
                'h-[48px]': !showAsLink && !isToggle,
                flex: buttonFlex,
                'text-gray-600 hover:text-gray-900 active:text-gray-600':
                    isSidebarIcon,
            }"
            :aria-label="ariaLabel"
            :disabled="disabled"
            tabindex="0"
            @keydown="keyHandler($event)"
            @click="toggleDropdown($event, true)"
        >
            <div class="flex" :class="{ 'justify-center': showAsLink }">
                <span v-if="!showAsMenu && !hasNoLabel" class="truncate">
                    <span
                        v-if="!chosenEntry || isToggle"
                        :class="{
                            'pr-3': isToggle && !showAsLink,
                            'underline hover:no-underline': showAsLink,
                        }"
                    >
                        {{ label }}
                    </span>
                    <template v-else>
                        <div
                            v-if="showWithColor && chosenEntry.color"
                            class="flex gap-3"
                        >
                            <div v-if="showWithColor" class="h-6 w-6 p-1">
                                <div
                                    class="h-full w-full rounded"
                                    :style="
                                        chosenEntry.color
                                            ? `background-color: ${chosenEntry.color}`
                                            : ''
                                    "
                                ></div>
                            </div>
                            <span>
                                {{ chosenEntry.label }}
                            </span>
                        </div>
                        <span v-else>
                            {{ chosenEntry.label }}
                        </span>
                    </template>
                </span>
                <div
                    :class="{
                        'text-blue-900': !isSidebarIcon,
                    }"
                >
                    <slot v-if="useOuterIcon" name="icon" />
                </div>
                <BaseIcon
                    v-if="
                        (!useOuterIcon || (isIconOnly && !isSidebarIcon)) &&
                        !showAsLink
                    "
                    class="my-auto ml-auto duration-150 ease-in"
                    :class="{
                        'rotate-180 text-blue-900': isOpen,
                        'rotate-0 text-gray-600': !isOpen && !disabled,
                        'rotate-0 text-gray-400': disabled,
                    }"
                    icon-name="arrow-down"
                />
            </div>
        </button>
        <BaseFixedContainer
            v-if="isOpen"
            :ref-element="button"
            :class="{ shadow: isToggle }"
            :max-height="240"
            :offset-top="offsetTop"
            :offset-right="offsetRight"
            :offset-left="offsetLeft"
            :fit-content="fitContent"
            :is-right-aligned="isRightAligned"
            :set-width-of-ref-elem="!useOuterIcon"
            full-border
            rounded
            @is-on-top="isShownOnTop = $event"
            @outside-scroll-or-resize="() => (isOpen = false)"
        >
            <ul
                v-if="isOpen && entries?.length"
                ref="list"
                data-testid="MtbaseFormSingleSelect List"
                class="overflow-auto overflow-x-hidden"
                :class="{
                    'pt-2': !isToggle,
                    'bg-gray-50': !isToggle && variant === 'gray',
                    'bg-white': isToggle || variant === 'white',
                    'border-t border-gray-300': isToggle,
                }"
            >
                <li
                    v-for="(entry, index) in entries"
                    :key="entry.id"
                    :data-testid="'li ' + entry.label"
                    class="h-8 w-full cursor-pointer truncate px-4 pt-3"
                    :class="{
                        'font-semibold':
                            chosenEntry?.id === entry.id && !showAsMenu,
                        'text-gray-300': isEntryDisabled(entry.id),
                        'hover:bg-gray-200': !isEntryDisabled(entry.id),
                    }"
                    :aria-disabled="isEntryDisabled(entry.id)"
                    :aria-current="chosenEntry?.id === entry.id"
                    @keydown="listKeyHandler($event, index)"
                    @click.stop="
                        isEntryDisabled(entry.id) ||
                        (!deselectElement &&
                            chosenEntry?.id === entry.id &&
                            !showAsMenu)
                            ? null
                            : entryClick(entry.id)
                    "
                >
                    <div
                        :class="{
                            'flex gap-3': showWithColor && entry.color,
                        }"
                    >
                        <div
                            v-if="showWithColor && entry.color"
                            class="h-6 w-6 p-1"
                        >
                            <div
                                class="h-full w-full rounded"
                                :style="
                                    entry.color
                                        ? `background-color: ${entry.color}`
                                        : ''
                                "
                            ></div>
                        </div>
                        <span>
                            {{ entry.label }}
                        </span>
                    </div>
                </li>
            </ul>
        </BaseFixedContainer>
    </div>
</template>
